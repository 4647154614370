import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import 'element-ui/lib/theme-chalk/index.css'
import ElementUI from 'element-ui';

import "swiper/dist/css/swiper.min.css"
import "./assets/css/public.css"
import "./assets/css/style.css"

Vue.use(ElementUI);

Vue.config.productionTip = false

// 引入dayjs
import dayjs from "dayjs"
Vue.prototype.dayjs = dayjs

import axios from 'axios'
Vue.prototype.$http = axios

Vue.prototype.apiUrl = "https://apizq.ghatg.com"
// Vue.prototype.apiName = "/jrzbjt/"
Vue.prototype.apiName = "/"
// 甘肃省金融资本集团有限公司
Vue.prototype.companyId = 10

new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')